import ApartmentIcon from '@mui/icons-material/Apartment';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import CalculateIcon from '@mui/icons-material/Calculate';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EngineeringIcon from '@mui/icons-material/Engineering';

import {
    // Truck as TruckIcon,
    // Briefcase as BriefcaseIcon,
    // Calendar as CalendarIcon,
    // ShoppingCart as ShoppingCartIcon,
    // Folder as FolderIcon,
    // BarChart as BarChartIcon,
    // Lock as LockIcon,
    // UserPlus as UserPlusIcon,
    // Shield as ShieldIcon,
    // AlertCircle as AlertCircleIcon,
    // Trello as TrelloIcon,
    // User as UserIcon,
    // Layout as LayoutIcon,
    // Edit as EditIcon,
    // DollarSign as DollarSignIcon,
    // Mail as MailIcon,
    // MessageCircle as MessageCircleIcon,
    PieChart as PieChartIcon,
    // Share2 as ShareIcon,
    Users as UsersIcon,
  } from "react-feather";

import { BUSINESS_TYPES, USER_ROLES } from 'src/config';

const UserNavConfig = [
    {
      subheader: "Dashboard",
      businesstype: [BUSINESS_TYPES.GENERAL],
      items: [
        {
          title: "Dashboard",
          icon: PieChartIcon,
          href: "/app/management/dashboard",
          role: [
            USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
          ],
        }
      ],
    },
    {
      subheader: "Project Management",
      businesstype: [BUSINESS_TYPES.GENERAL],
      items: [
        {
          title: "Project View",
          icon: ApartmentIcon,
          href: "/app/management/project",
          role: [
            USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
          ],
        },
        {
          title: "Projects",
          icon: ApartmentIcon,
          href: "/app/management/projects",
          role: [
            USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
          ],
        },
        // {
        //   title: "Project Assets",
        //   icon: RoomPreferencesIcon,
        //   href: "/app/management/project/assets",
        //   role: [
        //     USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
        //   ],
        // },
      ],
    },
    {
      subheader: "Master Configuration",
      businesstype: [BUSINESS_TYPES.GENERAL],
      items: [
        {
          title: "Assembly Master List",
          icon: EngineeringIcon,
          href: "/app/management/master/assembly/masters",
          role: [
            USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
          ],
        },
        {
          title: "Assembly Categories",
          icon: EngineeringIcon,
          href: "/app/management/master/assembly/categories",
          role: [
            USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
          ],
        },
      ],
    },
    // {
    //   subheader: "Analysis",
    //   businesstype: [BUSINESS_TYPES.GENERAL],
    //   items: [
    //     {
    //       title: "Engineering Inputs",
    //       icon: EngineeringIcon,
    //       href: "/app/management/analysis/inputs",
    //       role: [
    //         USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
    //       ],
    //     },
    //     {
    //       title: "Calculations",
    //       icon: CalculateIcon,
    //       href: "/app/management/analysis/calculation",
    //       role: [
    //         USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
    //       ],
    //     },
    //     {
    //       title: "Review",
    //       icon: ContentPasteSearchIcon,
    //       href: "/app/management/analysis/review",
    //       role: [
    //         USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
    //       ],
    //     },
    //   ],
    // },
    // {
    //   subheader: "Reports",
    //   businesstype: [BUSINESS_TYPES.GENERAL],
    //   items: [
    //     {
    //       title: "Schedule Generation",
    //       icon: FileCopyIcon,
    //       href: "/app/management/reports/schedules",
    //       role: [
    //         // USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
    //         USER_ROLES.ADMIN,
    //       ],
    //     },
    //     {
    //       title: "Reports",
    //       icon: SummarizeIcon,
    //       href: "/app/management/reports/reports",
    //       role: [
    //         //USER_ROLES.ENGINEER, USER_ROLES.ADMIN,
    //         USER_ROLES.ENGINEER, 
    //       ],
    //     },
    //   ],
    // },
  
  ];

  export default UserNavConfig;